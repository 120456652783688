/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React, { useState, useRef, useMemo, useEffect } from "react";
import moment from "moment";

import { useQuery } from "hooks";

import FilterBtnGroup from "components/filter-btn-group/filter-btn-group";
import CustomDivTable from "components/custom-table/custom-div-table";
import TitleBlock from "components/title-block/title-block";
import Button from "components/button/button";
import Icon from "components/icon/icon";
import CustomDivTableActions from "components/custom-table/custom-div-table-actions";
import ButtonIcon from "components/button-icon/button-icon";
import Tag from "components/tag/tag";

import ClubModal from "./club-modal";
import ClubArchiveModal from "./club-archive-modal";

export default function Clubs() {
  const activeClub = useRef();

  const [showClubModal, setShowClubModal] = useState(false);
  const [showClubRemoveModal, setShowClubRemoveModal] = useState(false);
  const [activeBtnGroup, setActiveBtnGroup] = useState("active");

  const { data: activeClubs, refetch: refetchActive } = useQuery(
    "/clubs?archived=false",
  );
  const { data: inactiveClubs, refetch: refetchInactive } = useQuery(
    "/clubs?archived=true",
  );

  const theme = useTheme();

  const combinedClubs = useMemo(() => {
    if (activeBtnGroup === "active") {
      return activeClubs?.data || [];
    } else if (activeBtnGroup === "inactive") {
      return inactiveClubs?.data || [];
    } else {
      return [...(activeClubs?.data || []), ...(inactiveClubs?.data || [])];
    }
  }, [activeClubs, inactiveClubs, activeBtnGroup]);

  useEffect(() => {
    console.log("Combined Clubs: ", combinedClubs);
  }, [combinedClubs]);

  function editClub(data) {
    if (data) {
      activeClub.current = data;
      setShowClubModal(true);
    }
  }

  function archiveClub(data, archive) {
    activeClub.current = { data, archive };
    setShowClubRemoveModal(true);
  }

  const filterBtnGroup = (
    <FilterBtnGroup
      options={["Active", "Inactive"]}
      activeBtnGroup={activeBtnGroup}
      setActiveBtnGroup={setActiveBtnGroup}
      style={{
        marginLeft: "auto",
        width: "auto",
      }}
    />
  );

  const columns = [
    {
      name: "name",
      valFn: (data) => `${data.name ? data.name : "--"}`,
      display: "Club name",
      sortable: false,
    },
    {
      name: "foundedAt",
      valFn: (data) => {
        return data?.founded_at
          ? moment(data.founded_at).utc().format("MM/DD/YYYY")
          : "--";
      },
      display: "Date founded",
      align: "right",
      alignHeader: "right",
    },
    {
      name: "owner",
      valFn: (data) => {
        return data?.leader_name || "--";
      },
      display: "Club owner name",
    },
    {
      name: "ownerEmail",
      valFn: (data) => {
        return data?.leader_email || "--";
      },
      display: "Club owner email",
    },
    {
      name: "status",
      valFn: (data) => {
        return <Tag tag={data?.status?.toLowerCase() || "--"} />;
      },
      display: "Status",
    },
    {
      name: "numMembers",
      valFn: (data) => {
        return data?._count?.users || "--";
      },
      display: "# Members",
      align: "right",
      alignHeader: "right",
    },
    {
      name: "actions",
      valFn: (it) => (
        <CustomDivTableActions>
          <ButtonIcon
            icon={<Icon icon="Edit" />}
            onClick={() => editClub(it)}
          />
        </CustomDivTableActions>
      ),
      display: "",
      noSort: true,
      align: "right",
      alignHeader: "right",
    },
  ];

  const handleCreate = () => {
    activeClub.current = null;
    setShowClubModal(true);
  };
  const btnCreate = (
    <Button
      icon={<Icon icon="Plus" />}
      iconPos="left"
      label="Add club"
      onClick={handleCreate}
    />
  );

  const actions = [btnCreate];

  const titleBlock = (
    <TitleBlock>
      {filterBtnGroup}
      {actions.map((btn, index) => (
        <React.Fragment key={index}>{btn}</React.Fragment>
      ))}
    </TitleBlock>
  );

  const modals = (
    <>
      {showClubModal && (
        <ClubModal
          setShowModal={setShowClubModal}
          club={activeClub.current}
          onSuccess={() => {
            refetchActive();
            refetchInactive();
          }}
        />
      )}

      {showClubRemoveModal && (
        <ClubArchiveModal
          setShowModal={setShowClubRemoveModal}
          club={activeClub.current}
          onAccept={() => {
            refetchActive();
            refetchInactive();
          }}
        />
      )}
    </>
  );

  return (
    <>
      {titleBlock}
      {/* <PageHeader title="4-H Clubs" component={filterBtnGroup} /> */}
      {/* {filterBtnGroup} */}

      <CustomDivTable
        columns={columns}
        rows={combinedClubs}
        rowCount={10}
        selectionMode="single"
        onSelect={(it) => {
          editClub(it);
        }}
        filterKeys={["name"]}
        activeBtnGroup={activeBtnGroup}
        showSearch={false}
        emptyMessage="No Clubs Found"
      />

      {modals}
    </>
  );
}
