/**@jsxImportSource @emotion/react */
import { useTheme } from "@emotion/react";

import React, { useState, useRef, useEffect } from "react";
import { Form, Formik, Field, ErrorMessage } from "formik";

import CustomModal from "components/custom-modal/custom-modal";
import { FormElement } from "components/form-element/form-element";

import { css } from "./css";
import Button from "components/button/button";

import StemForm from "./stem-form";

import { useMutation } from "hooks/use-mutation";

import APIClient from "client";

import { FormErrorMessage } from "validation/form-error-message";

export default function ModalClassStem({ setShowModal, refetch }) {
  const [stemType, setStemType] = useState("discipline");

  const scrollRef = useRef();
  const contentRef = useRef();

  const theme = useTheme();

  const conditionalClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    scrollRef.current?.getContent()?.scrollTo({
      top: contentRef.current.offsetTop,
      behavior: "smooth",
    });
  }, [stemType]);

  const { mutate: submitStem } = useMutation({
    mutationFn: ({ data }) => {
      return APIClient.post(
        StemForm.postUrl(stemType),
        StemForm.body(stemType, data),
      );
    },
    onSuccess: (res, { data, formik }) => {
      if (data.continue) {
        formik.resetForm();
        scrollRef.current?.getContent()?.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        setShowModal(false);
      }

      refetch();
    },
    onError: (error, { data, formik }) => {
      console.error(error);
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message;
        console.error("Error message:", errorMessage);
        if (error.response.data.field === "name") {
        }
      } else {
        formik.setErrors({
          submit: "Failed to save class stem. Please try again.",
        });
      }
      formik.setSubmitting(false);
    },
  });

  const headerTitle = "Create class stem";

  const stemTypeEls = [
    {
      id: "discipline",
      name: "stemType",
      label: "Discipline",
      helperText: "e.g. English, Western, Small Equine",
    },
    {
      id: "classType",
      name: "stemType",
      label: "Class Type",
      helperText: "e.g. Equitation, Showmanship",
    },
    {
      id: "level",
      name: "stemType",
      label: "Level",
      helperText: "e.g. Bronze, Walk/Job",
    },
    {
      id: "ageGroup",
      name: "stemType",
      label: "Age Group",
      helperText: "e.g. Junior (8-10), Senior (13-16)",
    },
    {
      id: "modifier",
      name: "stemType",
      label: "Modifier",
      helperText: "e.g. Greenhorse, Bareback",
    },
  ];

  const handleChange = (e) => {
    setStemType(e.target.value);
  };

  const radioButtonGroup = (els) => {
    return (
      <div css={css(theme).radioBtns}>
        {els.map((el) => (
          <FormElement
            key={el.id}
            element="RadioButton"
            id={el.id}
            name={el.name}
            label={el.label}
            helpertext={el.helperText}
            required
            value={el.id}
            onChange={(e) => handleChange(e)}
            checked={stemType === el.id}
          />
        ))}
      </div>
    );
  };

  const btnSaveAdd = (formik) => (
    <Button
      key="save-add"
      type="submit"
      color="primary"
      label="Save and add another"
      onClick={() => handleBtnSaveAdd(formik)}
      disabled={formik.isSubmitting}
    />
  );

  const handleBtnSaveAdd = (formik) => {
    formik.setFieldValue("continue", true);
    formik.handleSubmit();
  };

  const handleBtnSave = (formik) => {
    formik.setFieldValue("continue", false);
    formik.handleSubmit();
  };

  const btnSave = (formik) => (
    <Button
      key="submit"
      type="submit"
      color="primary"
      label="Save"
      onClick={() => handleBtnSave(formik)}
      disabled={formik.isSubmitting}
    />
  );

  const btnCancel = (
    <Button
      key="cancel"
      label="Cancel"
      onClick={conditionalClose}
      color="secondary"
      variant="outlined"
    />
  );

  const footerButtons = (formik) => [
    btnCancel,
    btnSaveAdd(formik),
    btnSave(formik),
  ];

  const getStemTypeContent = (type, formik) => {
    return (
      <div ref={contentRef} css={css(theme).formEls}>
        {StemForm.component(formik, type) || null}
      </div>
    );
  };

  return (
    <Formik
      initialValues={StemForm.initialValues(stemType)}
      validationSchema={StemForm.validationSchema(stemType)}
      onSubmit={(data, formik) => submitStem({ data, formik })}
      css={css(theme)}
    >
      {(formik) => (
        <Form>
          <CustomModal
            ref={scrollRef}
            headerTitle={headerTitle}
            footerButtons={footerButtons(formik)}
            onHide={conditionalClose}
            size="md"
          >
            <p>
              Class Stems are the granular pieces used to create a class for a
              show. This includes Discipline, Class Type, Age Group, and Level.
              Select the type of stem you'd like to create to begin.
            </p>

            {radioButtonGroup(stemTypeEls)}

            {getStemTypeContent(stemType, formik)}

            <div style={{ minHeight: "20px" }}>
              {formik.errors.submit && (
                <div className="form-error-msg" style={{ color: "red" }}>
                  {formik.errors.submit}
                </div>
              )}
            </div>
          </CustomModal>
        </Form>
      )}
    </Formik>
  );
}
