import React from "react";
import moment from "moment";

import { Calendar as CalendarPR } from "primereact/calendar";
import "primereact/resources/themes/saga-blue/theme.css";

import { FormErrorMessage } from "validation/form-error-message";

import css from "../form-element.module.css";

const Calendar = ({ required, formik, size, ...data }) => {
  const getLabel = data?.label && (
    <label>
      {data.label}
      {required && <span>*</span>}
    </label>
  );

  let val = "";
  let minVal = null;
  let maxVal = null;

  if (data?.datetime) {
    if (formik?.values[data?.name]) {
      val = moment(formik?.values[data?.name]).toDate();
    }

    if (data?.min) {
      minVal = moment(data?.min).toDate();
      delete data.min;
    }

    if (data?.max) {
      maxVal = moment(data?.max).toDate();
      delete data.max;
    }
  } else {
    if (formik?.values[data?.name]) {
      val = moment(formik?.values[data?.name]).toDate();
    }

    if (data?.min) {
      minVal = moment(data?.min).toDate();
      delete data.min;
    }

    if (data?.max) {
      maxVal = moment(data?.max).toDate();
      delete data.max;
    }
  }

  const elProps = {
    ...Object.keys(data).reduce((acc, key) => {
      if (key !== "data") {
        acc[key] = data[key];
      }
      return acc;
    }, {}),
    value: val,
  };
  if (minVal) elProps.minDate = minVal;
  if (maxVal) elProps.maxDate = maxVal;

  const getElement = (
    <CalendarPR
      {...elProps}
      showIcon
      iconPos="left"
      placeholder={data?.placeholder || "Select date"}
      className={css.calendar}
    />
  );

  return (
    <div className={css.formEl}>
      {getLabel}
      {getElement}
      {required && <FormErrorMessage name={data?.name} formik={formik} />}
    </div>
  );
};

export default Calendar;
